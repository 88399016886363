import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  learnerClickedBoardState,
  levelState,
  filterTeamLevelState,
  levelUpScoreState,
  savedScoreState,
  unsavedScoreState,
  isAllowTeacherMoveState,
  selectedTeamNumberState,
} from '../../recoil/atoms';
import BoardColumn from './BoardColumn';
import ConfirmModal from '../modal/ConfirmModal';
import useModal from '../../hooks/useModal';
import axios from 'axios';
import CarouselModal from '../modal/CarouselModal';
import FormModal from '../modal/FormModal';
import InputForm from '../InputForm';
import useForm from '../../hooks/useForm';
import usePath from '../../hooks/usePath';
import { QueryClient, useMutation } from '@tanstack/react-query';
import BoardHeader from './BoardHeader';
import codes from '../../assets/data/phaseHintCodes';
import PrimaryButton from '../common/PrimaryButton';
import toast from 'react-hot-toast';

const Board = () => {
  const phaseNames = ['int', 'dex', 'str'];
  // TODO: 외부 데이터 활용 (추후)
  const levelCount = 4;

  const { participant } = usePath();

  const teamNumber = useRecoilValue(selectedTeamNumberState);
  const clickedBoard = useRecoilValue(learnerClickedBoardState);
  const teamLevels = useRecoilValue(filterTeamLevelState);
  const levelUpScore = useRecoilValue(levelUpScoreState);

  const isAllowTeacherMove = useRecoilValue(isAllowTeacherMoveState);

  const [currentLevels, setCurrentLevels] = useRecoilState(levelState);
  const [savedScores, setSavedScores] = useRecoilState(savedScoreState);

  const [movingLevel, setMovingLevel] = useState(false);

  const { form, setForm, handleChange } = useForm();

  const {
    isModalOpen: isMoveModalOpen,
    openModal: openMoveModal,
    closeModal: closeMoveModal,
  } = useModal();

  const {
    isModalOpen: isHintModalOpen,
    openModal: openHintModal,
    closeModal: closeHintModal,
  } = useModal();

  const {
    isModalOpen: isCheckModalOpen,
    openModal: openCheckModal,
    closeModal: closeCheckModal,
  } = useModal();

  const checkHintMutation = useMutation({
    mutationFn: (solvedHintLevel) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/history/changeSolvedHint`,
        solvedHintLevel,
        {
          withCredentials: true,
        }
      ),
    onSuccess: (result) => {
      QueryClient.setQueryData(['getSolvedHint'], result.data.solved_hint);
    },
  });

  const moveLevel = async () => {
    setMovingLevel(true);
    const changedLevels = currentLevels.map((row) => [...row]);
    changedLevels[clickedBoard[0]][teamNumber - 1] = clickedBoard[1];

    const { data: levelData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/history/changeLevels`,
      { levels: changedLevels, teamNumber: teamNumber - 1, participant },
      {
        withCredentials: true,
      }
    );

    if (levelData === 'CANT MOVE NOW') {
      toast.error('마커 이동을 할 수 없습니다.');
      closeMoveModal();
      setMovingLevel(false);
      return;
    }

    setCurrentLevels(JSON.parse(levelData));

    if (!isAllowTeacherMove) {
      const diffLevel = clickedBoard[1] - teamLevels[clickedBoard[0]];
      const changedScores = [...savedScores];
      changedScores[teamNumber - 1] -= diffLevel * levelUpScore;

      const { data: scoreData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/history/changeScores`,
        { scores: changedScores },
        {
          withCredentials: true,
        }
      );
      setSavedScores(JSON.parse(scoreData));
    }

    closeMoveModal();
    toast.success('마커 이동이 완료되었습니다!');
    setMovingLevel(false);
  };

  const checkHint = async () => {
    if (form.checkHint !== codes[clickedBoard[0]])
      toast.error('확인코드가 일치하지 않습니다.');
    else {
      checkHintMutation.mutate({ solvedHintLevel: clickedBoard[0] + 1 });
      toast.success(
        `${phaseNames[clickedBoard[0]]}Lv${
          clickedBoard[1]
        }을(를) 해결하였습니다.`
      );
      closeCheckModal();
    }
  };

  const LevelIndicatorColumn = (
    <div className="grid grid-rows-4 gap-0 sm:gap-1 items-center">
      {Array.from({ length: levelCount }, (_, idx) => levelCount - 1 - idx).map(
        (level) => (
          <span
            key={level}
            className="text-base-200 text-4xl sm:text-xl font-extrabold"
          >
            {level}
          </span>
        )
      )}
    </div>
  );

  return (
    // TODO: 반응형 다시 고민
    <div className="h-full flex flex-col justify-center max-w-[700px] mx-auto">
      <BoardHeader />
      <div className="grid grid-cols-[15%_repeat(3,_minmax(25%,_100px))] md:grid-cols-[15%_repeat(3,_minmax(10rem,_25%))] sm:grid-cols-[10%_repeat(3,_30%)] px-2 py-2 sm:py-1 justify-center justify-items-center overflow-hidden">
        {LevelIndicatorColumn}
        {phaseNames.map((name, idx) => (
          <BoardColumn
            key={name}
            phaseIdx={idx}
            phaseName={name}
            levelCount={levelCount}
            currentPhaseLevels={currentLevels[idx]}
            openMoveModal={openMoveModal}
            openHintModal={openHintModal}
            movingLevel={movingLevel}
          />
        ))}
      </div>
      {isMoveModalOpen && (
        <ConfirmModal
          handleYes={moveLevel}
          handleNo={closeMoveModal}
          clickedBoard={clickedBoard}
        />
      )}
      {isHintModalOpen && (
        <CarouselModal
          clickedBoard={clickedBoard}
          closeModal={closeHintModal}
          openModal={openCheckModal}
        />
      )}
      {isCheckModalOpen && (
        <FormModal closeModal={closeCheckModal}>
          <InputForm
            label={'확인 코드를 입력해 주세요'}
            name={'checkHint'}
            message={'코드번호'}
            handleChange={handleChange}
          />
          <PrimaryButton
            label={'확인'}
            handleClick={checkHint}
            additionalClass="mt-5 rounded-[5px] px-8 py-2 w-full"
          />
        </FormModal>
      )}
    </div>
  );
};

export default Board;
