import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isAllowTeacherMoveState, unsavedScoreState } from '../../recoil/atoms';

const ScoreAdjuster = ({ idx, score, isAllow }) => {
  const [unsavedScores, setUnsavedScores] = useRecoilState(unsavedScoreState);
  const isAllowTeacherMove = useRecoilValue(isAllowTeacherMoveState);

  const handleIncrement = () => {
    const changedScores = [...unsavedScores];
    changedScores[idx] += 10;
    setUnsavedScores(changedScores);
  };

  const handleDecrement = () => {
    const changedScores = [...unsavedScores];
    changedScores[idx] -= 10;
    setUnsavedScores(changedScores);
    // TODO: 음수 허용 여부 재확인 (일단 0점으로 통일)
  };
  return (
    <div className="text-base-200 pl-5 py-1 rounded-md font-medium flex">
      {!isAllowTeacherMove && !isAllow.move && (
        <button
          className="w-4 bg-game-minus bg-contain bg-no-repeat bg-center"
          onClick={handleDecrement}
          disabled={unsavedScores[idx] <= 0}
        ></button>
      )}
      <span className="text-center text-xl px-1.5 min-w-14 w-14">{score}</span>
      {!isAllowTeacherMove && !isAllow.move && (
        <button
          className="w-4 bg-game-plus bg-contain bg-no-repeat bg-center"
          onClick={handleIncrement}
        ></button>
      )}
    </div>
  );
};

export default ScoreAdjuster;
