import React from 'react';
import { ReactComponent as Coin } from '../../assets/svg/learner/coin.svg';

const TeamScore = ({ score }) => {
  return (
    <div className="flex items-center">
      <Coin />
      <span className="ml-2 font-semibold text-xl">{score}</span>
    </div>
  );
};

export default TeamScore;
