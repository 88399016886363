import React from 'react';
import { useRouteError } from 'react-router-dom';

const Error = () => {
  const error = useRouteError();

  return (
    <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        페이지를 찾을 수 없습니다.
      </h1>
      <p className="mt-6 text-base leading-7 text-indigo-600">
        {error.statusText || error.message}{' '}
      </p>
    </div>
  );
};

export default Error;
// https://tailwindui.com/components/preview#component-ebef3404d06e7b5f42e85b7b2ca4a2a5
