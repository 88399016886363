import React from 'react';

const Toggle = ({ name, content, isAllow = 0, handleChange, size = 'md' }) => {
  const sizeOfToggle = { sm: 'toggle-sm', md: 'toggle-md' };

  return (
    <label className="cursor-pointer font-bold flex items-center justify-between text-base-200">
      {content && (
        <span className="sm:text-xs md:text-sm lg:text-md">{content}</span>
      )}
      <input
        type="checkbox"
        name={name}
        className={`toggle toggle-primary text-current ${sizeOfToggle[size]}`}
        checked={isAllow}
        onChange={({ target }) => handleChange(target.checked)}
      />
    </label>
  );
};

export default Toggle;
