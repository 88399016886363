import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isAllowTeacherMoveState,
  savedScoreState,
  selectedTeamNumberState,
  teamCountState,
  unsavedScoreState,
} from '../../recoil/atoms';
import ScoreAdjuster from './ScoreAdjuster';
import TeamIcon from '../common/TeamIcon';
import PrimaryButton from '../common/PrimaryButton';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

const TeamControlList = ({ isAllow }) => {
  const teamCount = useRecoilValue(teamCountState);
  const [teamNumber, setTeamNumber] = useRecoilState(selectedTeamNumberState);
  const [savedScores, setSavedScores] = useRecoilState(savedScoreState);
  const [unsavedScores, setUnsavedScores] = useRecoilState(unsavedScoreState);
  const [isAllowTeacherMove, setIsAllowTeacherMove] = useRecoilState(
    isAllowTeacherMoveState
  );

  const [isTeamScoreChanged, setIsTeamScoreChanged] = useState(
    Array(teamCount).fill(false)
  );

  const btnStyle =
    'font-semibold text-md px-2 py-2 rounded-full w-[80%] bg-blue-500 text-base-200 disabled:text-zinc-500 disabled:bg-transparent disabled:order disabled:border disabled:border-neutral-400';

  const handleSave = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/history/changeScores`,
      { scores: unsavedScores },
      {
        withCredentials: true,
      }
    );
    setSavedScores(JSON.parse(data));
    setUnsavedScores(JSON.parse(data));

    toast.success('저장되었습니다.');
  };

  const handleClickVisible = (teamNum) => {
    if (teamNumber === teamNum) setTeamNumber(0);
    else setTeamNumber(teamNum);
  };

  const {
    status: scoresStatus,
    data: scores,
    error: scoresError,
  } = useQuery({
    queryKey: ['getScores'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/history/getScores`, {
          withCredentials: true,
        })
        .then((res) => JSON.parse(res.data)),
    refetchInterval: 5000,
    enabled: isAllow?.move,
  });

  useEffect(() => {
    if (!scores) return;
    setUnsavedScores(scores);
    setSavedScores(scores);
  }, [scores]);

  useEffect(() => {
    setIsTeamScoreChanged(
      unsavedScores.map(
        (unsavedScore, idx) => unsavedScore !== savedScores[idx]
      )
    );
  }, [savedScores, unsavedScores]);

  const teamControlItem = (
    <div className="w-full flex flex-col justify-between">
      {/* TODO: scores를 여기서 뿌릴지/ ScoreAdjuster에서 받아서 띄울지 고민 -> 공식문서 따라 받아서 띄움 */}
      {unsavedScores?.map((score, idx) => (
        <div
          key={idx}
          className={`${
            isTeamScoreChanged[idx] ||
            (isAllowTeacherMove && teamNumber === idx + 1)
              ? 'border-primary bg-base-100 shadow-[0_0_0_2px] shadow-primary'
              : 'border-zinc-600 bg-gray-900'
          } border rounded-md m-2 px-5 py-1 flex justify-between items-center hover:border-primary hover:shadow-[0_0_0_2px] hover:shadow-primary`}
          onClick={() => {
            if (isAllowTeacherMove) setTeamNumber(idx + 1);
          }}
        >
          <div className="text-base-200 flex">
            {!isAllowTeacherMove && (
              <button
                className={`${
                  teamNumber === idx + 1
                    ? 'bg-game-visible'
                    : 'bg-game-invisible'
                } w-7 bg-contain bg-no-repeat bg-center mr-2`}
                onClick={() => handleClickVisible(idx + 1)}
              ></button>
            )}

            <TeamIcon groupNum={idx + 1} size="sm" />
          </div>
          <div>
            <ScoreAdjuster idx={idx} score={score} isAllow={isAllow} />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full flex flex-col justify-between items-center h-full">
      {teamControlItem}
      {!isAllowTeacherMove && !isAllow.move && (
        <PrimaryButton
          label={'Save'}
          handleClick={handleSave}
          disabled={isTeamScoreChanged.every((ischanged) => !ischanged)}
          additionalClass={btnStyle}
        />
      )}
    </div>
  );
};

export default TeamControlList;
