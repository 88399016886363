import React, { useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

const CheckLoginLayout = ({ children }) => {
  const navigate = useNavigate();

  //로그인이 되어있는지 체크
  const {
    data: teacherInfo,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['teacherInfo', children],
    queryFn: async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/setting/checkTeacherInfo`,
        {
          withCredentials: true,
        }
      );

      return data;
    },
  });

  //로그인이 되어있지 않으면 / 페이지로 보내기
  useEffect(() => {
    if (isError) {
      toast.error('로그인이 필요한 페이지 입니다.');
      navigate('/');
    } else if (teacherInfo === null) {
      toast.error('로그인이 필요한 페이지 입니다.');
      navigate('/');
    }
  }, [isError, teacherInfo]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || teacherInfo === null) {
    return null;
  }

  return <>{children}</>;
};

export default CheckLoginLayout;
