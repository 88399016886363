import React from 'react';
import { createPortal } from 'react-dom';

const ConfirmModal = ({ handleNo, handleYes, clickedBoard }) => {
  const phase = ['지', '덕', '체'];

  return createPortal(
    <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 modal-box bg-base-200 min-h-[30%] flex items-center justify-center flex-col">
      <div className="text-center text-lg">
        <p className="text-black">
          <span className="font-semibold">[{phase[clickedBoard[0]]}]</span>의
          레벨
          {clickedBoard[1]}(으)로 이동하시겠습니까?
        </p>
        <p className="text-red-600"> (이동하면 취소 불가합니다.)</p>
      </div>
      <div className="flex justify-end">
        <button
          className="px-4 py-3 bg-[#F5F5F5] text-gray-700 rounded absolute bottom-0 left-0 min-w-[50%] text-lg"
          onClick={handleNo}
        >
          취소
        </button>
        <button
          className="px-4 py-3 bg-[#3D7FFF] text-white rounded absolute bottom-0 right-0 min-w-[50%]"
          onClick={handleYes}
        >
          확인
        </button>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default ConfirmModal;
