/* global gcls */ //전역에 gcls라는 변수가 있음을 명시
import { useEffect } from 'react';

const Class = () => {
  const classList = {
    objtarget: 'class_area',
    gid: 1,
  };

  useEffect(() => {
    //전역변수 선언
    window.navigateToGame = () => {
      window.location.href = '/teacher/game';
    };
  }, []);

  useEffect(() => {
    let script = document.querySelector(
      `script[src="https://dev-race.connectplay.kr/class.pub.js"]`
    );

    if (!script) {
      script = document.createElement('script');
      script.src = 'https://dev-race.connectplay.kr/class.pub.js';
      script.async = true;
      document.body.appendChild(script);
    }
    if (script) {
      script.onload = () => {
        gcls.classlist(classList, 'navigateToGame(this)');
      };
    }
  }, []);

  return (
    <main className="flex flex-nowrap justify-center w-full">
      <div id="class_area" className="left-content"></div>
    </main>
  );
};

export default Class;
