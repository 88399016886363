import React from 'react';

const PrimaryButton = ({
  label,
  handleClick,
  disabled = false,
  additionalClass,
}) => {
  return (
    <button
      className={`bg-primary text-base-200 disabled:bg-[#8A8A8A] disabled:text-base-200 ${additionalClass} hover:bg-[#1C60E2]`}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default PrimaryButton;
