import React from 'react';

const Radio = ({ name, radios, selectedValue, handleChange }) => {
  return (
    <div className="flex">
      {radios.map((radio) => (
        <label className="label mr-6 p-0" key={radio.value}>
          <input
            type="radio"
            value={radio.value}
            name={name}
            checked={selectedValue === radio.value}
            className="radio border-base-200 checked:border-primary border-2 checked:border-4 checked:bg-base-300"
            onChange={({ target }) => handleChange(target.name, target.value)}
          />
          <span className="ml-2 text-sm">{radio.label}</span>
        </label>
      ))}
    </div>
  );
};

export default Radio;
