const hintImagePath = {
  // TODO: level0 이미지 폴더 애매, 경로 중복
  지: {
    lv0: ['/hint/g/level0.png'],
    lv1: ['/hint/g/level1_1.png', '/hint/g/level1_2.png'],
    lv2: ['/hint/g/level2_1.png', '/hint/g/level2_2.png'],
    lv3: ['/hint/g/level3.png'],
  },
  덕: {
    lv0: ['/hint/g/level0.png'],
    lv1: [
      '/hint/d/level1_1.png',
      '/hint/d/level1_2.png',
      '/hint/d/level1_3.png',
    ],
    lv2: [
      '/hint/d/level2_1.png',
      '/hint/d/level2_2.png',
      '/hint/d/level2_3.png',
      '/hint/d/level2_4.png',
      '/hint/d/level2_5.png',
    ],
    lv3: ['/hint/d/level3.png'],
  },
  체: {
    lv0: ['/hint/g/level0.png'],
    lv1: ['/hint/ch/level1_1.png', '/hint/ch/level1_2.png'],
    lv2: ['/hint/ch/level2_1.png', '/hint/ch/level2_2.png'],
    lv3: ['/hint/ch/level3.png'],
  },
};

export default hintImagePath;
