const gameTexts = {
  // TODO: 현대제철 이후 수정
  phase: { names: ['LV.', '지', '덕', '체'], desc: ['', 'int', 'dex', 'str'] },
  panel: {},
  leader: {
    title: '팀장 접속 현황',
    description:
      '조 이름 선택 후 [접속 끊기] 버튼을 누르면 해당 팀장은 세션이 종료되며 다른 팀원이 새롭게 팀장으로 참여할 수 있습니다.',
    button: '접속 끊기',
  },
};

export default gameTexts;
