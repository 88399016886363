import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePath = () => {
  const [participant, setParticipant] = useState('');
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    setParticipant(pathParts[1]);
  }, [location]);

  return { participant };
};

export default usePath;
