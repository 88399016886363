import React, { useEffect, useLayoutEffect, useState } from 'react';
import TeamControlList from '../../components/teacher/TeamControlList';
import Board from '../../components/board/Board';
import Toggle from '../../components/teacher/Toggle';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  companyState,
  teamCountState,
  levelState,
  levelUpScoreState,
  savedScoreState,
  unsavedScoreState,
  isAllowTeacherMoveState,
  selectedTeamNumberState,
} from '../../recoil/atoms';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { getIsAllowMove } from '../../api/game';
import InfoPanel from '../../components/teacher/InfoPanel';
import useModal from '../../hooks/useModal';
import FormModal from '../../components/modal/FormModal';
import PrimaryButton from '../../components/common/PrimaryButton';
import LeaderState from '../../components/teacher/LeaderState';
import { ReactComponent as CVLogoEng } from '../../assets/svg/logo/connectValue_eng.svg';
import { ReactComponent as CVLogoKo } from '../../assets/svg/logo/connectValue_ko.svg';
import gameTexts from '../../assets/data/gameTexts';
import toast from 'react-hot-toast';
import useAuthErrorHandler from '../../hooks/useAuthErrorHandler';

const Game = () => {
  const btnStyle =
    'mt-8 rounded-full text-2xl font-semibold py-3 w-full bg-blue-500 text-base-200 disabled:bg-neutral-400';
  const queryClient = new QueryClient();

  const handleAuthError = useAuthErrorHandler();

  const {
    isModalOpen: isLeadersModalOpen,
    openModal: openLeadersModal,
    closeModal: closeLeadersModal,
  } = useModal();

  const [isAllow, setIsAllow] = useState({ move: false, hint: false });

  const [companyName, setCompanyName] = useRecoilState(companyState);
  const [levelUpScore, setLevelUpScore] = useRecoilState(levelUpScoreState);
  const [teamCount, setTeamCount] = useRecoilState(teamCountState);
  const [savedScores, setSavedScores] = useRecoilState(savedScoreState);
  const [unsavedScores, setUnsavedScores] = useRecoilState(unsavedScoreState);
  const [levels, setLevels] = useRecoilState(levelState);
  const teamNumber = useRecoilValue(selectedTeamNumberState);
  const [isAllowTeacherMove, setIsAllowTeacherMove] = useRecoilState(
    isAllowTeacherMoveState
  );

  const [isTeamLeaderConnected, setIsTeamLeaderConnected] = useState(
    Array(teamCount).fill(false)
  );
  const [teamNumbersToDelete, setTeamNumbersToDelete] = useState([]);
  const [qrcodeUrl, setQrcodeUrl] = useState('');

  const moveMutation = useMutation({
    mutationFn: ({ isMove }) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/allow/changeStatusAllow`,
        { status: isMove ? 1 : 0 },
        {
          withCredentials: true,
        }
      );
    },
    onSuccess: (result) => {
      setIsAllow((prev) => ({ ...prev, move: result.data.status === 1 }));
      queryClient.setQueryData(['getStatusAllow'], (old) => ({
        ...old,
        status: result.data.status,
      }));
    },
  });

  const hintMutation = useMutation({
    mutationFn: ({ isHint }) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/allow/changeStatusAllow`,
        { status: isHint ? 2 : 0 },
        {
          withCredentials: true,
        }
      );
    },
    onSuccess: (result) => {
      setIsAllow((prev) => ({ ...prev, hint: result.data.status === 2 }));
      queryClient.setQueryData(['getStatusAllow'], (old) => ({
        ...old,
        status: result.data.status,
      }));
    },
  });

  const teacherMoveMutation = useMutation({
    mutationFn: (isTeacherMove) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/allow/changeStatusAllow`,
        { status: isTeacherMove ? 3 : 0 },
        {
          withCredentials: true,
        }
      );
    },
    onSuccess: (result) => {
      setIsAllowTeacherMove(result.data.status === 3);
      queryClient.setQueryData(['getStatusAllow'], (old) => ({
        ...old,
        status: result.data.status,
      }));
    },
  });

  // TODO: 상태들로 예외처리
  const {
    IsPending: isPendingAllow,
    data: dataAllow,
    isError: isErrorAllow,
    refetch,
  } = useQuery({
    queryKey: ['getStatusAllow'],
    queryFn: async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/allow/getStatusAllow`,
        {
          withCredentials: true,
        }
      );

      return data;
    },
    refetchInterval: 5000,
  });

  const {
    status: levelStatus,
    data: fetchedLevels,
    error: levelError,
  } = useQuery({
    queryKey: ['getLevelsRealTime'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/history/getLevels`, {
          withCredentials: true,
        })
        .then((res) => JSON.parse(res.data)),
    refetchInterval: 5000,
    enabled: dataAllow?.status === 1,
    onError: (error) => {
      handleAuthError();
    },
  });

  const changeMoveToggle = (checked) => {
    // TODO: 리팩토링
    if (isAllow.hint)
      return toast.error('[힌트 보기]옵션을 끈 후 시도해주세요.');
    else if (isAllowTeacherMove)
      return toast.error('[진행자 마커 제어모드]옵션을 끈 후 시도해주세요.');

    moveMutation.mutate({ isMove: checked });
    setIsAllow((current) => ({ ...current, move: checked }));

    toast.dismiss();
    toast.success(`[마커 이동]옵션이 ${checked ? '켜' : '꺼'}졌습니다.`);

    // TODO: 실시간으로 받아올지 고민
    if (!checked) {
      initializeScores();
      initializeLevels();
    }
  };

  const changeHintToggle = (checked) => {
    if (isAllow.move)
      return toast.error('[마커 이동]옵션을 끈 후 시도해주세요.');
    else if (isAllowTeacherMove)
      return toast.error('[진행자 마커 제어모드]옵션을 끈 후 시도해주세요.');

    hintMutation.mutate({ isHint: checked });
    setIsAllow((current) => ({ ...current, hint: checked }));

    toast.dismiss();
    toast.success(`[힌트 보기]가 ${checked ? '켜' : '꺼'}졌습니다.`);
  };

  const changeMoveToggleForTeacher = (checked) => {
    if (isAllow.move)
      return toast.error('[마커 이동]옵션을 끈 후 시도해주세요.');
    else if (isAllow.hint)
      return toast.error('[힌트 보기]옵션을 끈 후 시도해주세요.');

    teacherMoveMutation.mutate(checked);
    setIsAllowTeacherMove(checked);
  };

  const createQrCode = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/learner/createQrCode`,
      {
        withCredentials: true,
      }
    );

    setQrcodeUrl(data.url);
  };

  const initializeSchedule = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/setting/getCompanyInfo`,
      {
        withCredentials: true,
      }
    );

    const { company_name, level_up_score, team_count } = data;

    setCompanyName(company_name || 'error');
    setLevelUpScore(level_up_score || 'error');
    setTeamCount(team_count);
  };

  const initializeScores = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/history/getScores`,
      {
        withCredentials: true,
      }
    );

    setSavedScores(JSON.parse(data).slice(0, teamCount));
    setUnsavedScores(JSON.parse(data).slice(0, teamCount));
  };

  const initializeLevels = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/history/getLevels`,
        {
          withCredentials: true,
        }
      );

      setLevels(JSON.parse(data).map((phase) => phase.slice(0, teamCount)));
    } catch (error) {
      handleAuthError();
    }
  };

  const initializeLeadersStatus = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/learner/checkAndResetAllTeamLeaders`,
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return;
    } catch (error) {
      console.error('error');
    }
  };

  const initializeLeaders = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/learner/checkAllTeamLeaders`,
      {
        withCredentials: true,
      }
    );
    setIsTeamLeaderConnected(data);
    setTeamNumbersToDelete([]);
  };

  const deleteLeaders = async () => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/learner/deleteTeamLeaders`,
      teamNumbersToDelete,
      {
        withCredentials: true,
      }
    );
    setIsTeamLeaderConnected(data);
    setTeamNumbersToDelete([]);

    toast.success('접속 해제되었습니다.');
  };

  const selectTeamNumberToDelete = (teamNumber) => {
    if (teamNumbersToDelete.includes(teamNumber)) {
      setTeamNumbersToDelete(
        teamNumbersToDelete.filter(
          (teamNumberToDelete) => teamNumberToDelete !== teamNumber
        )
      );
    } else {
      setTeamNumbersToDelete((prev) => [...prev, teamNumber]);
    }
  };

  const panel = (
    <div className="w-full md:flex">
      <InfoPanel description={'본 게임 시 학습자 마커 이동을 제어합니다.'}>
        <Toggle
          content={'마커 이동'}
          isAllow={isAllow.move}
          handleChange={changeMoveToggle}
          name={'isMove'}
        />
      </InfoPanel>
      <InfoPanel description={'클로징 시 학습자 힌트 보기를 제어합니다.'}>
        <Toggle
          content={'힌트 보기'}
          isAllow={isAllow.hint}
          handleChange={changeHintToggle}
          name={'isHint'}
        />
      </InfoPanel>
      <InfoPanel line={true}>
        <div className="flex justify-between items-center text-sm border-b border-gray-800 px-4 py-4">
          <p>레벨 이동 시 사용점수 </p>
          <p className="font-semibold">{levelUpScore}점</p>
        </div>
        <div className="flex justify-between items-center text-sm border-b border-gray-800 px-4 py-4">
          <p>팀장 접속 현황 </p>
          <button className="underline" onClick={() => openLeadersModal()}>
            보기
          </button>
        </div>
        <div className="flex justify-between items-center text-sm px-4 py-4">
          <p>진행자 마커 제어모드</p>
          <Toggle
            isAllow={isAllowTeacherMove}
            handleChange={changeMoveToggleForTeacher}
            size="sm"
          />
        </div>
      </InfoPanel>
    </div>
  );

  useLayoutEffect(() => {
    initializeSchedule();
  }, []);

  useEffect(() => {
    // TODO: 각각 호출하는게 맞는지 추후 고민
    if (teamCount === 0) return;
    createQrCode();
    initializeScores();
    initializeLevels();
    initializeLeadersStatus();
  }, [teamCount]);

  useEffect(() => {
    if (!isLeadersModalOpen) return;
    // TODO: 모달 열렸을 때 실시간으로 받아오기
    initializeLeaders();
  }, [isLeadersModalOpen]);

  useEffect(() => {
    if (!dataAllow) return;

    setIsAllow({
      move: dataAllow.status === 1,
      hint: dataAllow.status === 2,
    });
    setIsAllowTeacherMove(dataAllow.status === 3);
  }, [dataAllow]);

  useEffect(() => {
    if (levelError) return;
    if (!fetchedLevels) return;

    setLevels(fetchedLevels);
  }, [fetchedLevels]);

  // TODO: 조 개수 반영하여 levelState 변경
  return (
    <div className="h-full bg-game-line-desktop bg-no-repeat bg-bottom bg-contain bg-opacity-95">
      <div className="w-full h-full bg-base-100 bg-opacity-50">
        <div className="flex md:flex-col items-center h-full w-full py-12 px-6">
          <aside className="lg:w-[30%] sm:w-full md:w-full h-full flex flex-col items-center justify-between lg:pr-5">
            <img
              src="/XZit/game/XZit_logo.png"
              alt="엑지트 로고"
              className="md:w-[50%]"
            />
            {panel}
            <img
              src={qrcodeUrl}
              alt="학습자 전용 QRCODE"
              className="w-[50%] md:absolute top-0 right-0 md:w-20"
            />
            <div className="md:absolute md:top-0 left-0">
              <CVLogoKo className="w-44 mb-3" />
              <CVLogoEng className="w-44" />
            </div>
          </aside>
          <div className="flex sm:flex-col items-center h-full w-full md:pb-20">
            <section className="w-full md:min-w-[500px] h-full relative">
              <div className="bg-gray-900 rounded-[15px] border-2 border-zinc-600 h-full bg-opacity-60">
                <Board />
                {isAllowTeacherMove && teamNumber === 0 && (
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center rounded-[15px]">
                    <p className="text-white bg-white bg-opacity-30 rounded-[10px] px-8 py-4">
                      오른쪽에서 마커를 이동할 조를 먼저 선택해주세요.
                    </p>
                  </div>
                )}
              </div>
            </section>
            <aside className="lg:w-[30%] h-full pl-5 pr-0">
              <TeamControlList isAllow={isAllow} />
            </aside>
          </div>
        </div>
      </div>
      {isLeadersModalOpen && (
        <FormModal closeModal={closeLeadersModal}>
          <div className="text-left text-base-200">
            <h2 className="text-white text-2xl font-semibold mb-4">
              {gameTexts.leader.title}
            </h2>
            <p>{gameTexts.leader.description}</p>
          </div>
          <div className="mt-8 flex flex-wrap justify-between items-center">
            {Array.from({ length: teamCount }, (_, index) => index + 1).map(
              (team) => (
                <LeaderState
                  key={team}
                  teamNumber={team}
                  disabled={!isTeamLeaderConnected[team - 1]}
                  handleClick={selectTeamNumberToDelete}
                  isSelected={teamNumbersToDelete.includes(team)}
                />
              )
            )}
          </div>
          <PrimaryButton
            label={gameTexts.leader.button}
            additionalClass={btnStyle}
            handleClick={() => deleteLeaders()}
            disabled={teamNumbersToDelete.length === 0}
          />
        </FormModal>
      )}
    </div>
  );
};

export default Game;
