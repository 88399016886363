import React from 'react';
import { createPortal } from 'react-dom';

const FormModal = ({ children, closeModal }) => {
  return createPortal(
    <div className="fixed inset-0 bg-base-100 bg-opacity-50">
      <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 modal-box border-2 border-zinc-600 bg-base-300 text-center pt-12 px-6">
        <button
          className="text-xl absolute right-2 top-4 w-10 h-10 text-base-200"
          onClick={closeModal}
        >
          ✕
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default FormModal;
