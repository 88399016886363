import React from 'react';
import BoardCell from './BoardCell';
import { useRecoilValue } from 'recoil';
import {
  isAllowTeacherMoveState,
  learnerAvailableMoveState,
  levelState,
  selectedTeamNumberState,
} from '../../recoil/atoms';
import usePath from '../../hooks/usePath';

const BoardColumn = ({
  phaseIdx,
  phaseName,
  levelCount,
  currentPhaseLevels,
  openMoveModal,
  openHintModal,
  movingLevel,
}) => {
  const { participant } = usePath();

  const levels = useRecoilValue(levelState);
  const teamNumber = useRecoilValue(selectedTeamNumberState);
  const availableMoves = useRecoilValue(learnerAvailableMoveState);
  const isAllowTeacherMove = useRecoilValue(isAllowTeacherMoveState);

  const boardCells = Array.from({ length: levelCount }, (_, rowIdx) => {
    const level = levelCount - rowIdx - 1;
    return (
      <BoardCell
        key={`${phaseName[phaseIdx]}${rowIdx}`}
        phase={phaseIdx}
        level={level}
        groupNums={
          // TODO: 리팩토링 (url 기준?)
          teamNumber > 0
            ? currentPhaseLevels[teamNumber - 1] === level
              ? [teamNumber]
              : []
            : currentPhaseLevels.reduce((arr, phaseLevel, groupIdx) => {
                if (phaseLevel === level) arr.push(groupIdx + 1);
                return arr;
              }, [])
        }
        openMoveModal={openMoveModal}
        openHintModal={openHintModal}
        canMove={
          (!movingLevel &&
            level > levels[phaseIdx][teamNumber - 1] &&
            level <= levels[phaseIdx][teamNumber - 1] + availableMoves) ||
          (isAllowTeacherMove && level !== levels[phaseIdx][teamNumber - 1])
        }
        canHint={
          participant === 'teacher' ||
          level === levels[phaseIdx][teamNumber - 1]
        }
      />
    );
  });

  return (
    <div className="w-full grid sm:grid-rows-[repeat(4,_25%)] gap-0 place-items-center">
      {boardCells}
    </div>
  );
};

export default BoardColumn;
