import React from 'react';

const InfoPanel = ({ description, children, line = false }) => {
  return (
    <div
      className={`w-full min-h-28 bg-gray-900 rounded-[15px] border-2 border-zinc-600 my-3 md:mx-2 flex flex-col justify-center items-stretch text-left text-base-200 ${
        line ? '' : 'px-4 py-4'
      }`}
    >
      {children}
      {description && <p className="text-sm font-normal mt-4">{description}</p>}
    </div>
  );
};

export default InfoPanel;
