import { useState } from 'react';

const useForm = () => {
  const [form, setForm] = useState({});

  const handleChange = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return {
    form,
    setForm,
    handleChange,
  };
};

export default useForm;
