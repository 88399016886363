import React from 'react';
import gameTexts from '../../assets/data/gameTexts';
import usePath from '../../hooks/usePath';

const BoardHeader = () => {
  const desc = gameTexts.phase.desc;
  const { participant } = usePath();
  const levelText = participant === 'teacher' ? 'LEVEL' : 'LV.';
  // TODO: 디바이스 크기에 따라 나누기? pc: LEVEL, 모바일: LV.

  return (
    <header className="grid grid-cols-[15%_repeat(3,_minmax(25%,_100px))] md:grid-cols-[15%_repeat(3,_minmax(10rem,_25%))] sm:grid-cols-[10%_repeat(3,_30%)] gap-0 px-2 py-2 sm:py-1 place-items-center justify-center sm:bg-zinc-800 w-full lg:rounded-t-[15px] md:rounded-t-[15px]">
      {gameTexts.phase.names.map((name, idx) => (
        <p
          key={name}
          className={`text-base-200 font-bold text-2xl sm:text-lg self-center`}
        >
          {idx === 0 ? levelText : name}
          <span className="text-sm sm:text-xs font-light">
            {desc[idx].toUpperCase()}
          </span>
        </p>
      ))}
    </header>
  );
};

export default BoardHeader;
