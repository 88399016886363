import React from 'react';
import axios from 'axios';
import TeamIcon from '../common/TeamIcon';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isAllowTeacherMoveState,
  learnerClickedBoardState,
  learnerSolvedHintState,
  learnerTeamLeaderState,
  selectedTeamNumberState,
} from '../../recoil/atoms';
import usePath from '../../hooks/usePath';
import { ReactComponent as HintLock } from '../../assets/svg/learner/lock.svg';

const BoardCell = ({
  phase,
  level,
  groupNums,
  openMoveModal,
  openHintModal,
  canMove,
  canHint,
}) => {
  const cells = [
    'bg-game-board-cell-int',
    'bg-game-board-cell-dex',
    'bg-game-board-cell-str',
  ];

  const { participant } = usePath();

  const teamNumber = useRecoilValue(selectedTeamNumberState);
  const isLeader = useRecoilValue(learnerTeamLeaderState);
  const solvedHint = useRecoilValue(learnerSolvedHintState);
  const isAllowTeacherMove = useRecoilValue(isAllowTeacherMoveState);
  const setClickedBoard = useSetRecoilState(learnerClickedBoardState);

  // TODO: teacher/Game 112줄과 겹침
  const {
    status,
    data: allow,
    error,
  } = useQuery({
    queryKey: ['getStatusAllow'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/allow/getStatusAllow`, {
          withCredentials: true,
        })
        .then((res) => res.data),
    refetchInterval: 5000,
  });

  const getBoardImage = () => {
    if (
      participant === 'teacher' ||
      (allow?.status !== 1 && allow?.status !== 2) ||
      (allow?.status === 1 && canMove) ||
      (allow?.status === 1 && groupNums.includes(teamNumber)) ||
      (allow?.status === 2 && canHint)
    ) {
      return cells[phase];
    } else {
      return 'bg-game-board-cell';
    }
  };

  const handleClick = () => {
    setClickedBoard([phase, level]);
    if (allow?.status === 2) openHintModal();
    else if (allow?.status === 1 || isAllowTeacherMove) openMoveModal();
  };

  return (
    <div
      className={`w-full h-full aspect-square bg-contain ${getBoardImage()} bg-no-repeat bg-center p-5`}
      disabled={true}
      onClick={handleClick}
      style={{
        pointerEvents:
          (allow?.status === 1 && canMove && isLeader) ||
          (allow?.status === 2 && canHint && phase <= solvedHint) ||
          (isAllowTeacherMove && canMove)
            ? 'auto'
            : 'none',
      }}
    >
      <div className="w-full h-full flex justify-center flex-wrap items-center">
        {groupNums.map((group) =>
          participant === 'learner' &&
          allow?.status === 2 &&
          phase > solvedHint ? (
            <HintLock key={phase} />
          ) : (
            <TeamIcon
              key={group}
              groupNum={group}
              size={participant === 'teacher' ? 'sm' : 'lg'}
              // TODO: 디바이스 크기별로 다르게
            />
          )
        )}
      </div>
    </div>
  );
};

export default BoardCell;
