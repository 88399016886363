import React from 'react';
import LeaderIcon from '../common/LeaderIcon';

const LeaderState = ({ teamNumber, disabled, handleClick, isSelected }) => {
  const btnAbledStyle = 'bg-base-100 ';
  const btnDisabledStyle = 'bg-zinc-600';
  const btnSelectedStyle =
    'shadow-[0_0px_8px_1px_rgba(0,0,0,0.3)] shadow-primary border border-primary';

  const spanAbledStyle = 'text-base-200';
  const spanDisabledStyle = 'text-neutral-400';

  return (
    <button
      disabled={disabled}
      className={`mt-5 rounded-[10px] border border-zinc-600 px-5 py-3 w-[40%] flex justify-center items-center ${
        disabled ? btnDisabledStyle : btnAbledStyle
      } ${isSelected ? btnSelectedStyle : ''}`}
      onClick={() => handleClick(teamNumber)}
    >
      <LeaderIcon size="sm" groupNum={disabled ? 0 : teamNumber} />
      <span
        className={`ml-3 text-base-200 text-3xl font-semibold ${
          disabled ? spanDisabledStyle : spanAbledStyle
        }`}
      >
        {teamNumber}조
      </span>
    </button>
  );
};

export default LeaderState;
