import { atom, selector } from 'recoil';

// 공통
export const companyState = atom({
  key: 'companyState',
  default: '기업명',
});

export const selectedTeamNumberState = atom({
  key: 'selectedTeamNumberState',
  default: 0,
  // 1부터 시작
  // 데이터의 idx는 0부터지만 엮여있는 코드(BoardColumn - selectedTem) 차후에 생각해보기
});

export const savedScoreState = atom({
  key: 'savedScoreState',
  default: [0, 0, 0, 0, 0, 0],
});

export const unsavedScoreState = atom({
  key: 'unsavedScoreState',
  default: [0, 0, 0, 0, 0, 0],
});

export const levelState = atom({
  key: 'levelState',
  default: [
    [0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0],
  ],
});

export const levelUpScoreState = atom({
  key: 'levelUpScoreState',
  default: 50,
});

// 강사
export const teamCountState = atom({
  key: 'teamCountState',
  default: 0,
});

export const isAllowTeacherMoveState = atom({
  key: 'isAllowTeacherMoveState',
  default: false,
});

// 학습자
export const learnerTeamLeaderState = atom({
  key: 'learnerTeamLeaderState',
  default: false,
});

export const learnerAvailableMoveState = atom({
  key: 'learnerAvailableMoveState',
  default: 0,
});

export const learnerClickedBoardState = atom({
  key: 'learnerClickedBoardState',
  default: [0, 0],
});

export const learnerSolvedHintState = atom({
  key: 'learnerSolvedHintState',
  default: 3,
});

// TODO: team 정보만 조회하는게 나을지 고민
export const filterTeamLevelState = selector({
  key: 'filterTeamLevel',
  get: ({ get }) => {
    const levels = get(levelState);
    const teamNum = get(selectedTeamNumberState);

    if (!levels || levels.length === 0) {
      // 기본값 반환하거나, 에러 처리
      return []; // 또는 throw new Error('Levels are not defined');
    }

    return levels.map((lvs) => lvs[teamNum - 1]);
  },
});

// export const teamState = atom({
//   key: 'teamState',
//   default: [{ number: 1, score: 20, level: [0, 0, 0] }],
// });
