import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const useAuthErrorHandler = () => {
  const navigate = useNavigate();

  const handleAuthError = () => {
    toast.dismiss();
    toast.error('식별 정보가 유효하지 않습니다. \n재접속 부탁드립니다.');
    const currentPath = window.location.pathname;
    const basePath = currentPath.substring(0, currentPath.lastIndexOf('/'));
    navigate(basePath);
  };

  return handleAuthError;
};

export default useAuthErrorHandler;
