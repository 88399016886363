import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Slider from 'react-slick';
import './slick-theme.css';
import './slick.css';
import hintImagePath from '../../assets/data/hintImagePath';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const CarouselModal = ({ clickedBoard, closeModal, openModal }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [loadedSlides, setLoadedSlides] = useState({});

  // TODO: 현대제철 이후 수정 (지,덕,체)
  const phase = ['지', '덕', '체'];
  // TODO: 리팩토링_ConfirmModal과 중복됨

  const settings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    swipe: false,
    swipeToSlide: false,
    centerMode: true,
    afterChange: (current) => {
      setActiveSlide(current);
    },
  };

  const currentPhase = phase[clickedBoard[0]];
  const currentLevel = clickedBoard[1];

  const handleImageLoad = (index) => {
    setLoadedSlides((prev) => ({ ...prev, [index]: true }));
  };

  useEffect(() => {
    if (loadedSlides[activeSlide]) {
      setLoadedSlides((prev) => ({ ...prev }));
    }
  }, [activeSlide]);

  return createPortal(
    <div className="fixed inset-0 bg-base-100 text-center">
      <h3 className="absolute top-[10%] left-1/2 transform -translate-x-1/2 mb-10 text-base-200 text-2xl font-semibold">
        <span className="after:ml-1">{currentPhase}</span>
        Lv
        {currentLevel}
      </h3>
      <button
        className="absolute top-[10%] right-10 color-base-200 font-semibold text-2xl"
        onClick={closeModal}
      >
        ✕
      </button>

      <button
        className="absolute top-[20%] left-1/2 transform -translate-x-1/2 bg-primary border-transparent px-8 py-2 rounded-[5px] text-base-200 text-md font-normal"
        onClick={() => {
          closeModal();
          openModal();
        }}
      >
        확인코드 입력
      </button>

      <div className="absolute top-[70%] left-1/2 transform -translate-x-1/2 -translate-y-[65%] w-full h-[65%]">
        <Slider {...settings}>
          {hintImagePath[currentPhase][`lv${currentLevel}`]?.map(
            (elem, idx) => (
              <div key={elem} className="w-full h-full">
                {activeSlide === idx && (
                  <TransformWrapper
                    centerOnInit={loadedSlides[idx]}
                    key={loadedSlides[idx] ? 'loaded' : 'loading'}
                    wrapperStyle={{ width: '100%' }}
                  >
                    <TransformComponent
                      wrapperStyle={{ width: '100%', height: '100%' }}
                    >
                      <img
                        onLoad={() => handleImageLoad(idx)}
                        alt={`${currentPhase}-${currentLevel}-hint${idx}`}
                        src={elem}
                        className="object-center object-contain max-h-full "
                      />
                    </TransformComponent>
                  </TransformWrapper>
                )}
              </div>
            )
          )}
        </Slider>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default CarouselModal;
