import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const Auth = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const uid = searchParams.get('uid');

  useEffect(() => {
    const createTeacherCookie = async (uid) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/setting/createTeacherCookie?uid=${uid}`,
        {
          withCredentials: true,
        }
      );

      if (data === 'success') {
        navigate('/teacher/class');
      } else {
        navigate('/');
      }
      return data;
    };

    if (uid) {
      createTeacherCookie(uid);
    } else {
      window.location.href = 'https://dev.connectplay.kr/signin?gid=1';
    }
  }, [uid, navigate]);

  return <div>Loading...</div>;
};

export default Auth;
