import React from 'react';
import { ReactComponent as Leader0 } from '../../assets/svg/leader/leader0.svg';
import { ReactComponent as Leader1 } from '../../assets/svg/leader/leader1.svg';
import { ReactComponent as Leader2 } from '../../assets/svg/leader/leader2.svg';
import { ReactComponent as Leader3 } from '../../assets/svg/leader/leader3.svg';
import { ReactComponent as Leader4 } from '../../assets/svg/leader/leader4.svg';
import { ReactComponent as Leader5 } from '../../assets/svg/leader/leader5.svg';
import { ReactComponent as Leader6 } from '../../assets/svg/leader/leader6.svg';
import { ReactComponent as Leader7 } from '../../assets/svg/leader/leader7.svg';
import { ReactComponent as Leader8 } from '../../assets/svg/leader/leader8.svg';
import { ReactComponent as Leader9 } from '../../assets/svg/leader/leader9.svg';
import { ReactComponent as Leader10 } from '../../assets/svg/leader/leader10.svg';
import { ReactComponent as Leader11 } from '../../assets/svg/leader/leader11.svg';
import { ReactComponent as Leader12 } from '../../assets/svg/leader/leader12.svg';

const LeaderIcon = ({ groupNum = 1, size = 'xs' }) => {
  const sizeClasses = {
    xs: 'h-6 w-6',
    sm: 'h-8 w-8',
    md: 'h-12 w-12',
    lg: 'h-16 w-16',
  };

  const sizeClass = sizeClasses[size];

  const colorVariants = [
    <Leader0 className={sizeClass} />,
    <Leader1 className={sizeClass} />,
    <Leader2 className={sizeClass} />,
    <Leader3 className={sizeClass} />,
    <Leader4 className={sizeClass} />,
    <Leader5 className={sizeClass} />,
    <Leader6 className={sizeClass} />,
    <Leader7 className={sizeClass} />,
    <Leader8 className={sizeClass} />,
    <Leader9 className={sizeClass} />,
    <Leader10 className={sizeClass} />,
    <Leader11 className={sizeClass} />,
    <Leader12 className={sizeClass} />,
  ];

  return <>{colorVariants[groupNum]}</>;
};

export default LeaderIcon;
