import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import { Toaster } from 'react-hot-toast';

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
    window.addEventListener('resize', setScreenSize);

    return () => window.removeEventListener('resize', setScreenSize);
  }, []);

  return (
    <div className="h-screen text-center h-[calc(var(--vh, 1vh) * 100)] font-sans">
      <Toaster
        toastOptions={{
          style: {
            width: 'auto',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <Outlet />
    </div>
  );
}

export default App;
