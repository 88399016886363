import React from 'react';
import { ReactComponent as Team1 } from '../../assets/svg/team/team1.svg';
import { ReactComponent as Team2 } from '../../assets/svg/team/team2.svg';
import { ReactComponent as Team3 } from '../../assets/svg/team/team3.svg';
import { ReactComponent as Team4 } from '../../assets/svg/team/team4.svg';
import { ReactComponent as Team5 } from '../../assets/svg/team/team5.svg';
import { ReactComponent as Team6 } from '../../assets/svg/team/team6.svg';
import { ReactComponent as Team7 } from '../../assets/svg/team/team7.svg';
import { ReactComponent as Team8 } from '../../assets/svg/team/team8.svg';
import { ReactComponent as Team9 } from '../../assets/svg/team/team9.svg';
import { ReactComponent as Team10 } from '../../assets/svg/team/team10.svg';
import { ReactComponent as Team11 } from '../../assets/svg/team/team11.svg';
import { ReactComponent as Team12 } from '../../assets/svg/team/team12.svg';

const TeamIcon = ({ groupNum, size = 'xs' }) => {
  const sizeClasses = {
    xs: 'h-6 w-6',
    sm: 'h-8 w-8',
    md: 'h-12 w-12',
    lg: 'h-16 w-16',
  };

  const sizeClass = sizeClasses[size];

  const colorVariants = [
    <Team1 className={sizeClass} />,
    <Team2 className={sizeClass} />,
    <Team3 className={sizeClass} />,
    <Team4 className={sizeClass} />,
    <Team5 className={sizeClass} />,
    <Team6 className={sizeClass} />,
    <Team7 className={sizeClass} />,
    <Team8 className={sizeClass} />,
    <Team9 className={sizeClass} />,
    <Team10 className={sizeClass} />,
    <Team11 className={sizeClass} />,
    <Team12 className={sizeClass} />,
  ];

  return <>{colorVariants[groupNum - 1]}</>;
};

export default TeamIcon;
