const axios = require('axios');

const getIsAllowMove = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/allow/getIsMove`,
    {
      withCredentials: true,
    }
  );
  return data;
};

export { getIsAllowMove };
