import React, { useState } from 'react';

const InputForm = ({
  label,
  name,
  type = 'text',
  inputmode = 'text',
  message,
  value,
  btnLabel,
  handleChange,
  validateInput,
  btnClick,
}) => {
  const [validationResult, setValidationResult] = useState('');

  const handleBlur = async (type, input) => {
    if (validateInput) setValidationResult(await validateInput(type, input));
  };

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <div className="my-4 text-lg">
        <div>
          <input
            name={name}
            className="input input-bordered w-full bg-[#191F2C] center grow-1 border-[#CCCCCC] placeholder-[#4E525B]"
            type={type}
            inputMode={inputmode}
            placeholder={message}
            value={value}
            onChange={({ target }) => handleChange(target.name, target.value)}
            onBlur={({ target }) => handleBlur(target.name, target.value)}
            id={name}
          />
          {validationResult && (
            <p className="pl-2 pt-2 text-left text-red-600 text-sm">
              {validationResult}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default InputForm;
